import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

import PageWrapper from '../components/PageWrapper'

const IndexPage = () => {
  return (
    <PageWrapper
      footerConfig={{
        style: 'style1',
      }}
    >
      <Container className="py-20">
        <Row>
          <Col>
            <p href="https://www.freepik.com/vectors/social-media">
              We use various images from freepik. Here is the attribution to
              these images:
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <a href="https://www.freepik.com/photos/background">
              Background photo created by rawpixel.com - www.freepik.com
            </a>
          </Col>
        </Row>
        <Row>
          <Col>
            <a href="https://www.freepik.com/vectors/social-media">
              Social media vector created by stories - www.freepik.com
            </a>
          </Col>
        </Row>
      </Container>
    </PageWrapper>
  )
}
export default IndexPage
